<template>
    <div class="sales-partner-details position-relative w-100 h-100 px-4 py-3 d-flex justify-content-center overflow-hidden">
        <b-modal v-model="modal" centered hide-header-close
                 ok-variant="danger"
                 cancel-variant="custom-light"
                 v-bind:ok-disabled="!password || !partnerNo"
                 v-on:ok="deleteSalesPartner"
                 v-on:hide="resetModal">
            <h3 slot="modal-title">Delete Sales Partner</h3>
            <h5 slot="modal-ok" class="m-0">Confirm</h5>
            <h5 slot="modal-cancel" class="m-0">Cancel</h5>
            <form action="" class="container-modal-form position-relative w-100 h-100 p-3">
                <div class="form-group position-relative">
                    <label for="password" class="font-weight-bold">Password</label>
                    <input type="password" id="password" class="form-control position-relative w-100"
                           v-bind:class="{'invalid': pwInvalid}"
                           v-model="password"
                           v-on:input="pwInvalid = false">
                </div>
                <div class="form-group position-relative">
                    <label for="modalPartnerNo" class="font-weight-bold">Partner No.</label>
                    <input type="text" id="modalPartnerNo" class="form-control position-relative w-100"
                           v-bind:class="{'invalid': partnerNoInvalid}"
                           v-model="partnerNo"
                           v-on:input="partnerNoInvalid = false">
                </div>
            </form>
        </b-modal>
        <div class="details position-relative w-50 h-100 rounded bg-white d-flex flex-column align-items-center flex-grow-1 overflow-hidden">
            <form-controls v-bind:msg="msg"
                           v-bind:msg-invalid="msgInvalid">
                <control-button slot="left"
                                v-bind:disabled="false"
                                v-bind:btn-title="backBtnTitle"
                                v-bind:btn-icon="['fas', 'arrow-left']"
                                v-bind:btn-class="backBtnClass"
                                v-on:btn-pressed="goBack"></control-button>
                <control-button slot="right"
                                v-bind:disabled="!savable"
                                btn-title="SAVE"
                                v-bind:btn-icon="['fas', 'hdd']"
                                btn-class="btn-custom-light ml-2"
                                v-on:btn-pressed="saveSalesPartner"></control-button>
                <control-button slot="right"
                                v-bind:disabled="!salesPartner"
                                btn-title="DELETE"
                                v-bind:btn-icon="['fas', 'trash-alt']"
                                btn-class="btn-danger ml-2"
                                v-on:btn-pressed="modal = true"></control-button>
            </form-controls>
            <form action="" class="container-form position-relative scroll-bar w-100 h-100 p-3">
                <div class="form-group position-relative w-100">
                    <label for="partnerNo" class="font-weight-bold">Partner No.</label>
                    <input type="text" id="partnerNo" class="form-control position-relative" placeholder=""
                           v-bind:value="localSalesPartner.partnerNo" readonly>
                </div>
                <div class="form-group position-relative w-100">
                    <label for="handler" class="font-weight-bold">Handler</label>
                    <input type="text" id="handler" class="form-control position-relative"
                           placeholder="Choose A Handler Among Your Staffs"
                           v-bind:class="invalidInputs.handler"
                           v-bind:value="localSalesPartner.handlerName" readonly>
                </div>
                <div class="form-group position-relative w-100">
                    <label for="name" class="font-weight-bold">Name</label>
                    <input type="text" id="name" placeholder="Partner Name" class="form-control position-relative"
                           v-bind:class="invalidInputs.name"
                           v-bind:value="localSalesPartner.name"
                           v-on:input="updateSalesPartner('name', $event.target.value)">
                </div>
                <div class="form-group position-relative w-100">
                    <label for="type" class="font-weight-bold">Type</label>
                    <select id="type" class="form-control position-relative"
                            v-bind:class="invalidInputs.type"
                            v-bind:value="localSalesPartner.type"
                            v-on:change="updateSalesPartner('type', $event.target.value)">
                        <option disabled hidden value=""></option>
                        <option>Company</option>
                        <option>Individual</option>
                    </select>
                </div>
                <div class="form-group position-relative w-100">
                    <label for="partnerNo" class="font-weight-bold">Sales Target</label>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">HK$</span>
                        </div>
                        <input type="text" id="target" class="form-control position-relative" placeholder=""
                               v-bind:value="localSalesPartner.salesTarget">
                    </div>
                </div>
                <div class="form-group position-relative w-100">
                    <label for="address" class="font-weight-bold">Address</label>
                    <input type="text" id="address" placeholder="Address" class="form-control position-relative"
                           v-bind:class="invalidInputs.address"
                           v-bind:value="localSalesPartner.address"
                           v-on:input="updateSalesPartner('address', $event.target.value)">
                </div>
                <div class="subform position-relative w-100 h-auto mb-3 border border-custom-light rounded"
                     v-for="(contact, index) in localSalesPartner.contacts" v-bind:key="index">
                    <div class="controls position-relative w-100 p-2 d-flex justify-content-center align-items-center">
                        <button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
                                v-bind:disabled="localSalesPartner.contacts.length === 1"
                                v-on:click="addDeleteContacts('delete', index)">
                            <font-awesome-icon class="position-relative w-90 h-100"
                                               v-bind:icon="['fas', 'minus']">
                            </font-awesome-icon>
                        </button>
                        <h4 class="title position-relative px-4 m-0 text-truncate">Contact {{index + 1}}</h4>
                        <button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
                                v-bind:disabled="localSalesPartner.contacts.length >= 10"
                                v-on:click="addDeleteContacts('add', index)">
                            <font-awesome-icon class="position-relative w-90 h-100"
                                               v-bind:icon="['fas', 'plus']">
                            </font-awesome-icon>
                        </button>
                    </div>
                    <div class="content position-relative w-100 p-3">
                        <div class="form-group position-relative w-100">
                            <label v-bind:for="`contact-name${index}`" class="font-weight-bold">Name</label>
                            <input type="text" v-bind:id="`contact-name${index}`" placeholder="Contact Name"
                                   class="form-control position-relative"
                                   v-bind:class="invalidContacts[index].name"
                                   v-bind:value="contact.name"
                                   v-on:input="updateContacts('name', $event.target.value, index)">
                        </div>
                        <div class="form-group position-relative w-100">
                            <label v-bind:for="`contact-phone${index}`" class="font-weight-bold">Phone</label>
                            <input type="text" v-bind:id="`contact-phone${index}`" placeholder="Contact Phone Number"
                                   class="form-control position-relative"
                                   v-bind:class="invalidContacts[index].phone"
                                   v-bind:value="contact.phone"
                                   v-on:input="updateContacts('phone', $event.target.value, index)">
                        </div>
                        <div class="form-group position-relative w-100">
                            <label v-bind:for="`contact-email${index}`" class="font-weight-bold">Email</label>
                            <input type="text" v-bind:id="`contact-email${index}`" placeholder="Contact Email"
                                   class="form-control position-relative"
                                   v-bind:class="invalidContacts[index].email"
                                   v-bind:value="contact.email"
                                   v-on:input="updateContacts('email', $event.target.value, index)">
                        </div>
                    </div>
                </div>

                <div class="container-form-group position-relative w-100">
                    <h6 class="position-relative mb-3 font-weight-bold">Related Orders</h6>
                    <div v-if="!localSalesPartner.orders || localSalesPartner.orders.length === 0"
                         class="form-group position-relative w-100 d-flex align-items-center">
                        <label for="campaign" class="mb-0 mr-3 font-weight-bold">1</label>
                        <input type="text" id="campaign" placeholder="Choose Order(s) from the table"
                               class="form-control position-relative"
                               value="" readonly>
                    </div>
                    <div class="form-group position-relative w-100 d-flex align-items-center"
                         v-for="(order, index) in localSalesPartner.orders"
                         v-bind:key="order">
                        <label v-bind:for="`order-${index}`" class="mb-0 mr-3 font-weight-bold">{{ index + 1 }}</label>
                        <input type="text" v-bind:id="`order-${index}`" placeholder=""
                               class="form-control position-relative"
                               v-bind:value="order" readonly>
                    </div>
                </div>

                <div class="form-group position-relative w-100">
                    <label for="notes" class="font-weight-bold">Notes</label>
                    <textarea id="notes" rows="6" class="form-control position-relative"
                              v-model="localSalesPartner.notes"></textarea>
                </div>
            </form>
        </div>
        <div class="table-selects position-relative w-50 h-100 ml-3 rounded d-flex flex-column align-items-end">
            <table-select class="mb-3"
                          title="Staff" filter="name" sort="name"
                          v-bind:order="1"
                          v-bind:fields="staffsFields"
                          v-bind:data-array="staffs"
                          v-bind:selectable="true"
                          v-bind:multi-selectable="false"
                          v-bind:selected-ids="[localSalesPartner.handler]"
                          v-on:row-clicked="selectHandler($event)">
            </table-select>
            <table-select title="Sales Order" filter="orderNo" sort="issuedAt"
                          v-bind:order="-1"
                          v-bind:fields="orderFields"
                          v-bind:data-array="salesOrders"
                          v-bind:selectable="true"
                          v-bind:multi-selectable="false"
                          v-bind:selected-ids="[localSalesPartner.handler]"
                          v-on:row-clicked="selectHandler($event)">
            </table-select>
        </div>
    </div>
</template>

<script>
import FormControls from "@/components/functional/form/FormControls";
import TableSelect from "@/components/functional/table/TableSelect";
import ControlButton from "@/components/functional/form/ControlButton";
import { v4 as uuidv4 } from 'uuid';
import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/lib/isEmail";
import {BModal} from "bootstrap-vue";
import { contactsDifferent } from "@/utility/helpers";
import {format} from "date-fns";

export default {
    name: "SalesPartnerDetails",

    components: {
        FormControls,
        TableSelect,
        ControlButton,
        BModal
    },

    props: {
        // props from vue router route params
        partnerId: {
            type: String,
        }
    },

    beforeRouteLeave(to, from, next) {
        this.modal = false;
        if (this.savable) {
            if (this.msg === "Leave Without Saving?") {
                // user has already been notified
                next();
            } else {
                this.toRoute = to.fullPath;
                this.msg = "Leave Without Saving?";
                this.msgInvalid = true;
                this.backBtnTitle = "YES!";
                this.backBtnClass = "btn-danger mr-2";
            }
        } else {
            next();
        }
    },

    created() {
        this.initSalesPartner();
        window.addEventListener("beforeunload", this.unloadHandler);
    },

    beforeDestroy() {
        window.removeEventListener("beforeunload", this.unloadHandler);
    },

    data() {
        return {
            localSalesPartner: {
                _id: "",
                partnerNo: "",
                name: "",
                type: "",
                address: "",
                contacts: [
                    {
                        name: "",
                        phone: "",
                        email: "",
                    },
                ],
                handler: "",
                notes: "",
                // added property
                handlerName: "",
            },
            invalidInputs: {
                name: "",
                type: "",
                address: "",
                handler: "",
            },
            invalidContacts: [
                {
                    name: "",
                    phone: "",
                    email: "",
                },
            ],
            orderFields: [
                { display: "Order No.", key: "orderNo", classBinding: ["f-2"] },
                { display: "Client", key: "client", classBinding: ["f-5"] },
                { display: "Items", key: "items", classBinding: [] },
                { display: "Currency", key: "currency", classBinding: [] },
                { display: "Total", key: "totalAmount", classBinding: ["f-2"] },
            ],
            staffsFields: [
                { display: "Name", key: "name", classBinding: ["f-4"] },
                { display: "Staff No.", key: "staffNo", classBinding: ["f-3"] },
                { display: "Gender", key: "gender", classBinding: [] },
                { display: "Position", key: "position", classBinding: ["f-4"] },
            ],
            msg: "",
            msgInvalid: false,
            modal: false,
            toRoute: "",
            backBtnTitle: "BACK",
            backBtnClass: "btn-custom-light mr-2",
            password: "",
            pwInvalid: false,
            partnerNo: "",
            partnerNoInvalid: false,
        }
    },

    computed: {
        salesPartner() {
            return this.$store.state.contents.salesPartners.find(({ _id }) => _id === this.partnerId);
        },

        savable() {
            // fall through pattern
            if (!this.salesPartner) return true;
            // can safely access properties inside this.salesPartner due to the first if clause in this function
            if (contactsDifferent(this.localSalesPartner.contacts, this.salesPartner.contacts)) return true;
            const keys = ["name", "type", "address", "handler", "notes"];
            return keys.some(key => this.localSalesPartner[key] !== this.salesPartner[key]);
        },

        staffs() {
            return this.$store.state.contents.staffs.map(({ _id, firstName, lastName, preferredName, staffNo, position, gender }) => {
                return {
                    _id: _id,
                    name: `${lastName} ${firstName}, ${preferredName}`,
                    staffNo: staffNo,
                    gender: gender,
                    position: position
                };
            });
        },

        salesOrders() {
            return this.$store.state.contents.salesOrders.map(order => {
                const obj = {...order};
                const client = this.$store.state.contents.clients.find(({ _id }) => _id === order.client);
                const issuedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === order.issuedBy);
                obj.client = client ? `${client.name} (${client.clientNo})` : "N/A";
                obj.issuedBy = issuedBy ? `${issuedBy.preferredName} ${issuedBy.lastName}` : "N/A";
                obj.issuedAt = order.issuedAt ? format(new Date(order.issuedAt), "yyyy-MM-dd") : "N/A";
                obj.items = order.items.length; // type Number
                return obj;
            });
        }
    },

    methods: {
        unloadHandler(event) {
            if (this.savable) {
                event.preventDefault();
                event.returnValue = '';
            }
        },

        resetModal(bvModalEvent) {
            if (bvModalEvent.trigger !== "ok") {
                this.password = "";
                this.pwInvalid = false;
                this.partnerNo = "";
                this.partnerNoInvalid = false;
            }
        },

        initSalesPartner() {
            if (this.salesPartner) {
                const obj = {...this.salesPartner};
                obj.contacts = [];
                this.invalidContacts = [];
                this.salesPartner.contacts.forEach(contact => {
                    obj.contacts.push({...contact});
                    this.invalidContacts.push({ name: "", phone: "", email: "" });
                });
                const handler = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.handler);
                // added property
                obj.handlerName = handler ? `${handler.lastName} ${handler.firstName}, ${handler.preferredName} (${handler.staffNo})` : "N/A";
                this.localSalesPartner = obj;
            } else {
                this.localSalesPartner._id = uuidv4();
                this.localSalesPartner.partnerNo = "SPR-" + this.localSalesPartner._id.split("-")[0].toUpperCase();
            }
        },

        addDeleteContacts(option, index) {
            if (option === "add") {
                this.localSalesPartner.contacts.splice(index + 1, 0, { name: "", phone: "", email: "" });
                this.invalidContacts.splice(index + 1, 0, { name: "", phone: "", email: "" });
            } else {
                this.localSalesPartner.contacts.splice(index, 1);
                this.invalidContacts.splice(index, 1);
            }
        },

        selectHandler(staff) {
            this.localSalesPartner.handler = staff._id;
            this.localSalesPartner.handlerName = `${staff.name} (${staff.staffNo})`;
            this.invalidInputs.handler = "";
        },

        updateSalesPartner(key, value) {
            this.localSalesPartner[key] = value;
            // check if value is just white spaces
            this.invalidInputs[key] = value.trim().length > 0 ? "" : "invalid";
        },

        updateContacts(key, value, index) {
            this.localSalesPartner.contacts[index][key] = value;
            switch (key) {
                case "phone":
                    // nullish check is included
                    this.invalidContacts[index][key] = isMobilePhone(value) ? "" : "invalid";
                    break;
                case "email":
                    // nullish check is included
                    this.invalidContacts[index][key] = isEmail(value) ? "" : "invalid";
                    break;
                default:
                    this.invalidContacts[index][key] = value.trim().length > 0 ? "" : "invalid";
                    break;
            }
        },

        // only set invalid, shouldn't set it back to empty string
        // nullish check only, no other validations since they are being taken care of in input event handlers
        dataInvalid() {
            const salesPartnerKeys = Object.keys(this.invalidInputs);
            const contactKeys = ["name", "phone", "email"];
            salesPartnerKeys.forEach(key => {
                if (!this.localSalesPartner[key] ||
                    this.localSalesPartner[key].trim().length === 0) this.invalidInputs[key] = "invalid";
            });
            this.invalidContacts.forEach((contact, index) => contactKeys.forEach(key => {
                if (!this.localSalesPartner.contacts[index][key] ||
                    this.localSalesPartner.contacts[index][key].trim().length === 0) contact[key] = "invalid"; //object reference
            }));
            return salesPartnerKeys.some(key => this.invalidInputs[key] === "invalid") ||
                this.invalidContacts.some(contact => contactKeys.some(key => contact[key] === "invalid"));
        },

        async saveSalesPartner() {
            this.backBtnTitle = "BACK";
            this.backBtnClass = "btn-custom-light mr-2";
            if (this.dataInvalid()) {
                this.msg = "Please Fix Invalid Fields!";
                this.msgInvalid = true;
            } else {
                // need not do deep copy for contacts since initSalesPartner will be run upon successful saving
                const salesPartner = {...this.localSalesPartner};
                delete salesPartner.handlerName;
                await this.$store.dispatch("contents/updateContent", { key: "salesPartners", value: salesPartner});
                if (this.partnerId) {
                    this.initSalesPartner();
                    this.msg = "Previous Changes Saved!";
                    this.msgInvalid = false;
                } else {
                    // bypassing beforeRouteLeave guard
                    this.msg = "Leave Without Saving?";
                    await this.$router.push(`/sales/partner/${salesPartner._id}`);
                    // need to run initSalesPartner after route change since salesPartnerId prop was undefined
                    this.initSalesPartner();
                    // vue router reuses this component
                    this.msgInvalid = false;
                    this.msg = "New Sales Partner Created!";
                }
            }
        },

        async deleteSalesPartner(bvModalEvent) {
            if (this.password !== this.$store.state.user.user.password) {
                bvModalEvent.preventDefault();
                this.pwInvalid = true;
                if (this.partnerNo !== this.localSalesPartner.partnerNo) this.partnerNoInvalid = true;
            } else if (this.partnerNo !== this.localSalesPartner.partnerNo) {
                this.partnerNoInvalid = true;
                bvModalEvent.preventDefault();
            } else {
                await this.$store.dispatch("contents/deleteContent", { key: "salesPartners", _id: this.salesPartner._id});
                // bypassing beforeRouteLeave guard
                this.msg = "Leave Without Saving?";
                await this.$router.push("/sales/partner");
            }
        },

        async goBack() {
            if (this.msg === "Leave Without Saving?") {
                await this.$router.push(this.toRoute);
            } else {
                await this.$router.push("/sales/partner");
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/form";
</style>
